<template>
  <div class="card q-pa-sm shadow bg-white rounded">
    <div class="text-subtitle2 text-center border-bottom q-pb-xs">
      {{ data.name }} <span>({{ data.id }})</span>
    </div>

    <div class="row q-py-sm fit">
      <div class="col-3 row">
        <img
          class="m-auto"
          style="height: 80px; width: 80px; object-fit: contain;"
          :src="data.image || fallbackImage"
          :alt="data.name"
          @error="onImageLoadFailure"
        >
      </div>

      <div class="col-9">
        <div v-if="data.quantityInfo">
          <div
            v-if="data.quantityInfo.scannedCount > 0 && countDifference !== 0"
            class="row items-center justify-center"
          >
            <div class="border border-gray rounded q-pa-xs">
              <strong>
                {{ originalItem.quantityInfo.scannedCount || 0 }}
              </strong>
            </div>

            <div class="q-pa-xs">
              <span v-if="originalItem.quantityInfo.scannedCount < data.quantityInfo.scannedCount">
                +
              </span>

              <span v-else>
                -
              </span>
            </div>

            <div class="border border-warning rounded q-pa-xs">
              <strong>
                {{ countDifference }}
              </strong>
            </div>

            <div class="q-pa-xs">
              =
            </div>

            <div class="border border-success rounded q-pa-xs">
              <strong>
                {{ data.quantityInfo.scannedCount || 0 }}
              </strong>
            </div>

            <div class="q-pa-xs">
              <strong>
                {{ '/' + data.quantityInfo.count }}
              </strong>
            </div>
          </div>

          <div
            v-else
            class="row items-center"
          >
            <div class="col-4">
              {{ $t('Amount') }}
            </div>

            <div class="col-8 text-subtitle1 q-mb-xs">
              <strong>
                {{ data.quantityInfo.scannedCount + '/' + data.quantityInfo.count }}
              </strong>
            </div>
          </div>
        </div>

        <div class="row items-center">
          <div class="col-4">
            {{ $t('Sku') }}
          </div>

          <div class="col-8 text-subtitle1">
            {{ data.sku }}
          </div>
        </div>

        <div class="row items-center">
          <div class="col-4">
            {{ $t('Order') }}
          </div>

          <div class="col-8 text-subtitle1">
            {{ order }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PickingItem',
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      countDifference: 0,
      originalItem: {
        quantityInfo: {
          scannedCount: 0
        }
      },
      fallbackImage: 'assets/img/fallback-image/package.png'
    }
  },
  computed: {
    order () {
      if (this.data.order) {
        return this.data.order
      }

      if (this.data._embedded && this.data._embedded.reserve && this.data._embedded.reserve._embedded && this.data._embedded.reserve._embedded.order) {
        const parts = this.data._embedded.reserve._embedded.order._links.self.href.split('/')
        return parts[parts.length - 1]
      }

      return ''
    }
  },
  watch: {
    data: {
      handler (value) {
        if (value && value.sku !== this.originalItem.sku) {
          this.originalItem = { ...value }
          this.countDifference = 0
          return
        }

        this.countDifference = this.getCountDifference(this.data.quantityInfo.scannedCount, this.originalItem.quantityInfo.scannedCount)
      },
      deep: true
    }
  },
  mounted () {
    this.originalItem = { ...this.data }
  },
  methods: {
    onImageLoadFailure (e) {
      e.target.src = this.fallbackImage
    },
    getCountDifference (originalCount = 0, newCount = 0) {
      if (isNaN(Number(originalCount))) {
        originalCount = 0
      }

      if (isNaN(Number(newCount))) {
        newCount = 0
      }

      return originalCount - newCount
    }
  }
}
</script>
